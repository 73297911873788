import { mapUrlToConfigV2 } from "@signicat/signicat-mfes-config-mapping";

function cdnHost(url) {
  url = new URL(url);
  return url.hostname === "localhost"
    ? "https://static.signicat.dev"
    : url.origin;
}

function addPrime(styleVersion) {
  let baseUrl = getPrimeAssetsBaseUrl();
  let link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = `${baseUrl}/styles-${styleVersion}.css`;

  return link;
}

function getPrimeAssetsBaseUrl(){
  return cdnHost(mapUrlToConfigV2(window.location.origin)) + "/prime-assets";
}

export { addPrime, getPrimeAssetsBaseUrl };
