import { unregisterApplication, getAppNames } from "single-spa";
import checkProvider from "./contexts/checkProvider.js";
import registerApp from "./registerApp.js";
import { isLoading } from "./utils.js";
import { allApps } from "./loader.js";

async function updateApps() {
  isLoading(true);
  let registeredApps = getAppNames();
  for (const element of allApps) {
    if (registeredApps.indexOf(element.name) !== -1) {
      // apps which are registered but should not be
      if (!checkProvider(element.provider)) {
        await unregisterApplication(element.name);
      }
    } else {
      // apps which are not registered but should be
      if (checkProvider(element.provider)) {
        try {
          registerApp(element);
        } catch (error) {
          console.error(`Failed to register app ${element.name}:`, error);
        }
      }
    }
  }
  isLoading(false);
}

export default updateApps;
