function addAnalytics(document, gaTag) {

    // Defensive approach: don't set anything if `gaTag` is not present nor defined
    // in the configuration (that is the root-config.json file).
    if (!gaTag) {
        return
    }

    // Setting and adding the <noscript> block as first element of the <body>.
    let iframeElem = document.createElement("iframe");
    iframeElem.setAttribute("height", "0");
    iframeElem.setAttribute("width", "0");
    iframeElem.setAttribute("style", "display:none;visibility:hidden");
    iframeElem.setAttribute(
        "src",
        "https://www.googletagmanager.com/ns.html?id=" + gaTag
    );
    let noscriptElem = document.createElement("noscript");
    noscriptElem.appendChild(iframeElem);
    document.body.insertBefore(noscriptElem, document.body.firstChild);

    // Setting and adding the <script> elements in the <header>.
    let script2Elem = document.createElement("script");
    let script2ChildElem = document.createTextNode('(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","' + gaTag + '");')
    script2Elem.appendChild(script2ChildElem);
    document.head.insertBefore(script2Elem, document.head.firstChild);

    let script1Elem = document.createElement("script");
    script1Elem.setAttribute("src", "https://static.signicat.com/assets/legal/openli_widget.js");

    document.head.insertBefore(script1Elem, document.head.firstChild);
}

export { addAnalytics };
