import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { allApps } from "./loader.js";
import pubsub from "@signicat/pubsub"; 

export default function initializeSentry(settings) {
  if (settings && settings.environment && settings.dsn) {
    Sentry.init({
      dsn: settings.dsn,
      environment: settings.environment,
      integrations: [
        new Integrations.BrowserTracing()
      ],
      tracesSampleRate: 0.1,
    });
    Sentry.addGlobalEventProcessor((event) => createTagsWhenEventOccursInMFE(event));
  }
}

export function createTagsWhenEventOccursInMFE(event) {
  try {
    // Initialize all tags to N/A so they always have a value
    event.tags = {
      "sig.mfe.name": "N/A",
      "sig.mfe.host": "N/A",
      "sig.mfe.version": "N/A",
      "sig.mfe.backstageComponentName": "N/A",
      "sig.scope.organizationId": "N/A",
      "sig.scope.accountId" : "N/A"
    }
    const scope = pubsub.lastValue("scope");
    event.tags["sig.scope.organizationId"] = scope.organization?.id ?? "N/A";
    event.tags["sig.scope.accountId"] = scope.account?.id ?? "N/A";
    // We rely on Sentry events and stack traces
    // * https://develop.sentry.dev/sdk/event-payloads/
    // * https://develop.sentry.dev/sdk/event-payloads/stacktrace/
    const stackframesFilenames = event?.exception?.values.map(value => value.stacktrace?.frames.map(frame => frame.filename)).flat();
    // we reverse the list because the relevant app we are trying to identify is the last one in the stack frames
    if (stackframesFilenames !== undefined) {
      for (let filename of stackframesFilenames.reverse()) {
        let filenamePath = filename.slice(0, filename.lastIndexOf("/") + 1);
        if (filenamePath) {
          const app = allApps.find((app) => app.host.startsWith(filenamePath));
          if (app !== undefined) {
            event.tags["sig.mfe.name"] = app.name;
            event.tags["sig.mfe.host"] = app.host;
            event.tags["sig.mfe.backstageComponentName"] = app.backstageComponentName;
            // N/A is set because we want the MFE to have versions
            event.tags["sig.mfe.version"] = app.data?.version ?? "N/A";
            break; // Terminate the filenames loop
          }
        }
      }
    }
  } catch (exception) {
    console.log(exception);
  }
  return event;
}
