import pubsub from '@signicat/pubsub';
import { isMatchingCloud } from '@signicat/signicat-mfe-cloud-mapping';

function providerMatch(providers, cloud) {
  let providerCloudMatch = false;

  for (const provider of providers) {
    if (isMatchingCloud(provider, cloud)) {
      providerCloudMatch = true;
    }
  }
  return providerCloudMatch;
}

function checkProvider(providers) {
  const scope = pubsub.lastValue('scope');

  return (
    scope?.account?.cloud === undefined ||
    providers == null ||
    providers.includes('*') ||
    providerMatch(providers, scope?.account?.cloud)
  );
}

export default checkProvider;
