/* istanbul ignore file */
import {
  apiSubdomain,
  apiUrlKey,
  dashboardSubdomain, devSignicatDomain, ENVIRONMENT,
  environmentKey,
  isSignicatTenantKey,
  prodSignicatDomain, qaSignicatDomain
} from "./globalVariables.js";

function addSessionStorageData() {

  const environment = ENVIRONMENT;
  const isLocal = process.env.NODE_ENV === 'development';
  const domain = window.location.hostname;

  switch (environment) {
    case "prod":
      sessionStorage.setItem(isSignicatTenantKey, domain === `${dashboardSubdomain}.${prodSignicatDomain}` ? "true" : "false");
      sessionStorage.setItem(apiUrlKey, `${apiSubdomain}.${prodSignicatDomain}`);
      sessionStorage.setItem(environmentKey, environment);
      break;
    case "qa":
      sessionStorage.setItem(isSignicatTenantKey, domain === `${dashboardSubdomain}.${qaSignicatDomain}` ? "true" : "false");
      sessionStorage.setItem(apiUrlKey, `${apiSubdomain}.${qaSignicatDomain}`);
      sessionStorage.setItem(environmentKey, environment);
      break;
    case "dev":
      sessionStorage.setItem(isSignicatTenantKey, domain === `${dashboardSubdomain}.${devSignicatDomain}` ? "true" : "false");
      sessionStorage.setItem(apiUrlKey, `${apiSubdomain}.${devSignicatDomain}`);
      sessionStorage.setItem(environmentKey, environment);
      break;
    default:
      if (isLocal) {
        sessionStorage.setItem(isSignicatTenantKey, "true");
        sessionStorage.setItem(apiUrlKey, `${apiSubdomain}.${devSignicatDomain}`);
        sessionStorage.setItem(environmentKey, "dev");
      } else {
        sessionStorage.setItem(isSignicatTenantKey, "true");
        sessionStorage.setItem(apiUrlKey, `${apiSubdomain}.${prodSignicatDomain}`);
        sessionStorage.setItem(environmentKey, "prod");
      }
  }
}

export { addSessionStorageData };
