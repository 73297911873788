import { timedCacheBustingHash } from "./cacheBusting.js";
import HttpExceptionLogger from "./httpExceptionLogger.js";

let allApps = [];

function loader(name, host, route, container, provider) {
  if (host.endsWith(".html") || host.endsWith(".htm")) {
    const htmlUrl = new URL("", host);
    const htmlPath = htmlUrl.pathname + "?v=" + timedCacheBustingHash(10);
    allApps.push({
      name,
      host: htmlUrl.origin,
      route,
      data: { html: htmlPath },
      container,
      provider,
    });
    return Promise.resolve();
  }

  const manifestUrl =
    new URL("manifest.json", host).href + "?v=" + timedCacheBustingHash(10);
  return fetch(manifestUrl)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      allApps.push({ name, host, route, data, container, provider });
    })
    .catch((error) => new HttpExceptionLogger().logError(error));
}

export { loader, allApps };
