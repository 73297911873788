import { addPrime } from "./addPrime.js";
import { addAnalytics } from "./addAnalytics.js";
import { addUserpilot } from "./addUserPilot.js";
import { start } from "single-spa";
import pubsub from "@signicat/pubsub";
import {
  registerApps,
  isLoading,
  hasAccess,
  goHomeListener,
  resetMenu,
} from "./utils.js";
import updateApps from "./updateApps.js";
import "animate.css";
import "src/styles/index.css";
import initializeSentry from "./initializeSentry.js";
import { addSessionStorageData } from "./addSessionStorageData.js";
import { getConfigurationV2 } from "@signicat/signicat-mfes-config-mapping";

async function startApplication() {
  isLoading(true);
  addSessionStorageData();

  const data= await getConfigurationV2(window.location.origin, "/root-config.json")
  initializeSentry(data.sentry);

  const apps = data.apps;
  document.head.appendChild(
    addPrime(data.styleVersion)
  );
  addAnalytics(document, data.gaTag);
  addUserpilot(data.userPilot);
  const globalApps = apps.filter((app) => app.isGlobal);
  const otherApps = apps.filter((app) => !app.isGlobal);

  await registerApps(globalApps)
  await updateApps();
  start({
    urlRerouteOnly: true,
  });
  await registerApps(otherApps, "apps")
  pubsub.emitLatestEvent("hasAccess", hasAccess);
  pubsub.subscribe("hasAccess", hasAccess);

  pubsub.emitLatestEvent("scope", updateApps);
  pubsub.subscribe("scope", updateApps);
}

startApplication()
  .then(() => {
    goHomeListener();
    resetMenu();
  });