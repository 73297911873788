import { loader } from "./loader.js";
import pubsub from "@signicat/pubsub";
import { isSignicatTenant } from "@signicat/signicat-mfes-config-mapping";

function isLoading(loading) {
  document.getElementById("initial-loading").style.display = loading
    ? "block"
    : "none";
}

function hasAccess(data) {
  let bodyClassList = document.body.classList;
  if (data.detail === true) {
    bodyClassList.add("logged-in");
    bodyClassList.remove("logged-out");
  } else {
    bodyClassList.remove("logged-in");
    bodyClassList.add("logged-out");
  }
}

async function registerApps(apps) {
  const loaded = apps.map(async (app) => {
    return await loader(
      app.name,
      app.host,
      app.route,
      app.container ? app.container : "apps",
      app.provider ? app.provider : ["*"]
    );
  });
  return await Promise.allSettled(loaded); // Wait for all promises to finish, either resolved or rejected.
}

function goHomeListener() {
  document.getElementById("goHome").addEventListener("click", function (event) {
    event.preventDefault(); // Prevent the default action (navigating to the href)
    let newUrl;
    if (isSignicatTenant()) {
      newUrl = "/";
    } else {
      newUrl = "/dashboard";
    }
    const newTitle = "Signicat Dashboard";
    window.history.pushState(window.history.state, newTitle, newUrl);
  });
}

function resetMenu() {
  window.addEventListener("popstate", (evt) => {
    if (evt.singleSpaTrigger === "pushState") {
      pubsub.publish("displayMenu", true);
    }
  });
}

export { isLoading, registerApps, hasAccess, goHomeListener, resetMenu };
